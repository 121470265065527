.filterContainer {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
}

.selectContainer {
  width: 200px;
}

.selectOptions {
  width: 200px;
  padding-left: 16px;
}

.formInputContainer {
  width: 300px;
  position: relative;
  padding-left: 16px;
}

.errorContainer {
  padding-left: 16px;
}

.dateTimeMargin {
  margin: 0 10px;
}

.searchButtonMargin {
  margin-left: 30px;
}

.resetButtonMargin {
  margin-left: 10px;
}
