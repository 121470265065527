.container {
  display: flex;
  margin-left: 10px;
  margin-bottom: 25px;
  align-items: center;
}

.labelContainer {
  width: 420px;
}

.valueContainer {
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
  text-align: left;
}
