.formWrapper {
  padding: 12px;
  padding-top: 16px;
  background: #f5f5fa;
  margin-bottom: 8px;
}

.form {
  margin: 0;
  width: 100%;
}

.textFieldLabel {
  font-weight: 400;
}

.textField :global .MuiSelect-select {
  background-color: white;
  font-size: 14px;
  padding: 11.5px 35px 11.5px 17px;
  height: 10px;
}

.menuItem {
  font-size: 14px;
}

.loadButtonContainer {
  display: flex;
  padding-top: 8px;
}

.loadMore {
  width: 100px;
  display: flex;
  justify-content: flex-start;
  height: 30px;
}

.loadMore:hover {
  background-color: white !important;
}
