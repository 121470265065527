.container {
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 20px;
}

.label {
  display: flex;
  white-space: nowrap;
  width: 146px;
}

.result {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 210px;
  max-height: 40px;
}
