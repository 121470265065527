/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/

/* @font-face {
  font-family: "Roboto";
  src: url("../../../public/assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../../../public/assets/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: bold;
} */

:root {
  /* Set the font family of the entire app */
  --mega-font-family: "Roboto" !important;

  /**primary**/
  --mega-color-primary: #4faee0;
  --mega-color-primary-rgb: 79, 174, 224;
  --mega-color-primary-contrast: #ffffff;
  --mega-color-primary-contrast-rgb: 0, 0, 0;
  --mega-color-primary-shade: #4faee0;
  --mega-color-primary-tint: #4faee0;

  /** secondary **/
  --mega-color-secondary: #45c8b5;
  --mega-color-secondary-rgb: 69, 200, 181;
  --mega-color-secondary-contrast: #ffffff;
  --mega-color-secondary-contrast-rgb: 255, 255, 255;
  --mega-color-secondary-shade: #45c8b5;
  --mega-color-secondary-tint: #45c8b5;

  /** tertiary **/
  --mega-color-tertiary: #002c54;
  --mega-color-tertiary-rgb: 0, 44, 84;
  --mega-color-tertiary-contrast: #000000;
  --mega-color-tertiary-contrast-rgb: 0, 0, 0;
  --mega-color-tertiary-shade: #002c54;
  --mega-color-tertiary-tint: #002c54;

  /** success **/
  --mega-color-success: #a0c167;
  --mega-color-success-rgb: 160, 193, 103;
  --mega-color-success-contrast: #000000;
  --mega-color-success-contrast-rgb: 0, 0, 0;
  --mega-color-success-shade: #a0c167;
  --mega-color-success-tint: #a0c167;

  /** warning **/
  --mega-color-warning: #f4da7f;
  --mega-color-warning-rgb: 244, 218, 127;
  --mega-color-warning-contrast: #000000;
  --mega-color-warning-contrast-rgb: 0, 0, 0;
  --mega-color-warning-shade: #f4da7f;
  --mega-color-warning-tint: #f4da7f;

  /* error status color */
  --mega-color-error: #e88949;
  --mega-color-error-rgb: 232, 137, 73;
  --mega-color-error-contrast: #000000;
  --mega-color-error-contrast-rgb: 0, 0, 0;
  --mega-color-error-shade: #e88949;
  --mega-color-error-tint: #e88949;

  /** danger **/
  --mega-color-danger: #d73c23;
  --mega-color-danger-rgb: 215, 60, 35;
  --mega-color-danger-contrast: #ffffff;
  --mega-color-danger-contrast-rgb: 255, 255, 255;
  --mega-color-danger-shade: #d73c23;
  --mega-color-danger-tint: #d73c23;

  /** dark  #434a54 - primary text color**/
  --mega-color-gray: #434a54;
  --mega-color-gray-rgb: 64, 74, 84;
  --mega-color-gray-contrast: #ffffff;
  --mega-color-gray-shade: #434a54;
  --mega-color-gray-tint: #434a54;

  /* lighter text color -#656d78 subtitle */
  --mega-color-gray500: #656d78;
  --mega-color-gray500-rgb: 101, 109, 120;
  --mega-color-gray500-contrast: #ffffff;
  --mega-color-gray500-contrast-rgb: 255, 255, 255;
  --mega-color-gray500-shade: #656d78;
  --mega-color-gray500-tint: #656d78;

  /** medium  now gray 400**/
  --mega-color-gray400: #aab2bd;
  --mega-color-gray400-rgb: 170, 178, 189;
  --mega-color-gray400-contrast: #000000;
  --mega-color-gray400-contrast-rgb: 0, 0, 0;
  --mega-color-gray400-shade: #aab2bd;
  --mega-color-gray400-tint: #aab2bd;

  /* define later */
  --mega-color-gray300: #ccd1d9;
  --mega-color-gray300-rgb: 204, 209, 217;
  --mega-color-gray300-contrast: #000000;
  --mega-color-gray300-contrast-rgb: 0, 0, 0;
  --mega-color-gray300-shade: #ccd1d9;
  --mega-color-gray300-tint: #ccd1d9;

  /* lighter text color - example subtitle  */
  --mega-color-gray200: #e6e9ed;
  --mega-color-gray200-rgb: 230, 233, 237;
  --mega-color-gray200-contrast: #000000;
  --mega-color-gray200-contrast-rgb: 0, 0, 0;
  --mega-color-gray200-shade: #e6e9ed;
  --mega-color-gray200-tint: #e6e9ed;

  /** was light now gray100 - #f5f5fa devider lines**/
  --mega-color-gray100: #f5f5fa;
  --mega-color-gray100-rgb: 245, 247, 250;
  --mega-color-gray100-contrast: #000000;
  --mega-color-gray100-contrast-rgb: 0, 0, 0;
  --mega-color-gray100-shade: #f5f5fa;
  --mega-color-gray100-tint: #f5f5fa;

  /* background */
  --mega-color-background: #e6e9ed;
  --mega-color-background-rgb: 230, 233, 237;
  --mega-color-background-contrast: #000000;
  --mega-color-background-contrast-rgb: 0, 0, 0;
  --mega-color-background-shade: #e6e9ed;
  --mega-color-background-tint: #e6e9ed;

  /* tabs background */
  --mega-color-tabs: #ffffff;
  --mega-color-tabs-rgb: 230, 233, 237;
  --mega-color-tabs-contrast: #000000;
  --mega-color-tabs-contrast-rgb: 0, 0, 0;
  --mega-color-tabs-shade: #ffffff;
  --mega-color-tabs-tint: #ffffff;

  /* quick install help background */
  --mega-color-quickInstallBackground: #ffffff;
  --mega-color-quickInstallBackground-rgb: 230, 233, 237;
  --mega-color-quickInstallBackground-contrast: #000000;
  --mega-color-quickInstallBackground-contrast-rgb: 0, 0, 0;
  --mega-color-quickInstallBackground-shade: #e6e9ed;
  --mega-color-quickInstallBackground-tint: #e6e9ed;

  /* card */
  --mega-color-card: #ffffff;
  --mega-color-card-rgb: 230, 233, 237;
  --mega-color-card-contrast: #000000;
  --mega-color-card-contrast-rgb: 0, 0, 0;
  --mega-color-card-shade: #ffffff;
  --mega-color-card-tint: #ffffff;

  /* CEL-FI colors */
  --cel-fi-green: #a0c167;

  /* button disabled background */
  --mega-color-disabledButton: #ccd1d9;

  /* button disabled text */
  --mega-color-disabledButtonText: #f5f5fa;

  /* Divider color */
  --mega-color-dividerColor: var(--mega-color-gray100);

  /* Progress bar background color */
  --mega-color-progressBarBackground: #f5f5fa;

  /* TextField Dropdown Menu background color */
  --mega-color-textfieldDropdown: #f5f5fa;

  /* TextField Dropdown Menu background color */
  --mega-color-dropdownText: #434a54;

  /* TextField Dropdown Box Shadow */
  --mega-color-dropdownShadow: rgba(163, 163, 163, 0.74);
}
