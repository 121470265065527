.container {
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: transparent;
  text-align: end;
  padding-top: 25px;
}

.textContainer {
  display: flex;
  justify-content: flex-end;
}
