.markerWrapper {
  position: relative;
  padding: 0px 0px 10px 0px;
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.sliderContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 40px;
}

.sliderLabelContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mapToolsWrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
}
