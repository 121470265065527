.container {
  padding: 30px 20px 20px 20px;
  overflow: auto;
  flex: 1;
  height: 100%;
}

@media (max-width: 768px) {
  .container {
    margin-left: 0;
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
}
