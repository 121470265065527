.container :global .MuiDialog-container .MuiPaper-root {
  border-radius: 16px;
  min-width: 500px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  padding-top: 32px;
}

.alignContentEvenly {
  display: flex;
  justify-content: flex-end;
}

.alignContentEnd {
  display: flex;
  justify-content: flex-end;
}

.topMargin {
  margin-top: 24px;
}
